import React, { useState } from "react"
import { useMitt } from "react-mitt"
import { CLoadingButton } from "@coreui/react-pro"
import { useAudioConvert } from "src/common/api/CommonApi"

import useVocaSentService from "../hook/useVocaSentService"
import useS3Client from "src/common/hook/s3/useS3Client"

import useUtils from "src/common/utils/useUtils"

const AudioLodingBtn = ({ data, mutate, bucketType, listMutate }) => {
  const { emitter } = useMitt()
  const [loading, setLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const { trigger: reqTextToAudio } = useAudioConvert()

  const { formatFileName } = useUtils()

  //delete 관련 메소드
  const { deleteVocaSentFileInfo } = useVocaSentService()
  const { deleteR2File } = useS3Client()

  const converToAudios = [
    {
      file_column: "sent_mp3_m",
      text: data.sent_en,
      file_name: formatFileName(data?.sent_en, "_M_"),
      file_table: "voca_sent_info",
      bucket_name: "sentence-bucket",
      model_id: "eleven_turbo_v2",
      voice_id: process.env.REACT_APP_VOICE_M_ID,
      id: data.sent_id,
      duration_name: "sent_mp3_m_ss",
      convert_info: "sent_mp3_m_ts",
      file_id: "sent_id"
    },
    {
      file_column: "sent_mp3_w",
      text: data.sent_en,
      file_name: formatFileName(data?.sent_en, "_W_"),
      file_table: "voca_sent_info",
      bucket_name: "sentence-bucket",
      model_id: "eleven_turbo_v2",
      voice_id: process.env.REACT_APP_VOICE_W_ID,
      id: data.sent_id,
      duration_name: "sent_mp3_w_ss",
      convert_info: "sent_mp3_w_ts",
      file_id: "sent_id"
    },
    {
      file_column: "sent_mp3_k",
      text: data.sent_kr,
      file_name: formatFileName(data?.sent_en, "_K_"),
      file_table: "voca_sent_info",
      bucket_name: "sentence-bucket",
      model_id: "eleven_multilingual_v2",
      voice_id: process.env.REACT_APP_VOICE_K_ID,
      id: data.sent_id,
      duration_name: "sent_mp3_k_ss",
      convert_info: "sent_mp3_k_ts",
      file_id: "sent_id"
    }
  ]

  const handleConvert = async (audios, data) => {
    try {
      setLoading(true)

      const convert = audios.filter((item) => !data[item.file_column])

      if (convert.length === 0) {
        emitter.emit("alert", { message: "이미 음원이 등록되었습니다.", type: 300 })
        setLoading(false)
        return
      }

      for (const item of convert) {
        try {
          const response = await reqTextToAudio(item)
          console.log("check response: ", response)

          if (response.data.res_code === "200") {
            emitter.emit("alert", { message: `파일명: ${response.data.res_data} 음원 파일이 등록되었습니다.`, type: 200 })
          }
          await mutate()
        } catch (error) {
          console.log(error)
          emitter.emit("alert", { message: "음원 등록에 실패했습니다. 잠시후 다시 시도해주세요.", type: 500 })
          return
        }
      }

      setLoading(false)
      await listMutate()
    } catch (error) {
      console.error("convert to audio fail...", error)
    }
  }

  const toDeleteAudios = [
    { key: "sent_mp3_m", fileName: data?.sent_mp3_m },
    { key: "sent_mp3_w", fileName: data?.sent_mp3_w },
    { key: "sent_mp3_k", fileName: data?.sent_mp3_k }
  ]

  const deleteAudios = async () => {
    const filtered = toDeleteAudios.filter((item) => data[item.key])
    setDeleteLoading(true)
    for (const item of filtered) {
      try {
        await deleteR2File(bucketType, item.fileName)

        await deleteVocaSentFileInfo({
          sent_id: data?.sent_id,
          [item.key]: item.fileName
        })
        await mutate()
      } catch (error) {
        console.error("fail to delete audios", error)
        emitter.emit("alert", { message: `파일삭제 실패했습니다.`, type: 200 })
      }
    }

    setDeleteLoading(false)
  }

  return (
    <div className="d-flex">
      <CLoadingButton
        loading={loading}
        onClick={() => handleConvert(converToAudios, data)}
        size="sm"
        color="primary"
        className="px-3 me-2"
      >
        음원 등록
      </CLoadingButton>

      <CLoadingButton loading={deleteLoading} onClick={() => deleteAudios()} size="sm" className="px-3" color="danger">
        음원 삭제
      </CLoadingButton>
    </div>
  )
}

export default AudioLodingBtn
