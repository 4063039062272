import React from "react"
import { NavLink, useLocation } from "react-router-dom"
import PropTypes from "prop-types"
import { CBadge } from "@coreui/react-pro"
import gridStore from "src/common/grid/store/GridStore"
import usePathStore from "src/common/store/PathStore"
export const AppSidebarNav = ({ items, menus }) => {
  const { resetAllState } = gridStore()
  //items 대 분류 카테고리 menus 하위 데이터

  //서버로그인 사용자에 맞는 메뉴화면구성
  const filteredNavigation = items.filter((navi) => {
    return menus.indexOf(navi.to) > -1
  })

  const onClickSidebarNav = () => {
    resetAllState()
  }

  const location = useLocation()
  const navLink = (name, icon, badge) => {
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, navTitle, badge, icon, ...rest } = item
    const Component = component

    if (navTitle) {
      return (
        <Component {...(rest.to && !rest.items)} key={index} {...rest}>
          {navLink(name, icon, badge)}
        </Component>
      )
    }

    return (
      <Component
        onClick={() => {
          onClickSidebarNav()
        }}
        {...(rest.to &&
          !rest.items && {
            component: NavLink
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item, index) => {
    //서버로그인 사용자에 맞는 메뉴화면구성
    //let filteredSubItems = []
    if (item.items) {
      item.items = item.items.filter((subItem) => {
        // console.log(subItem)
        return menus.indexOf(subItem.to) > -1
      })
    }

    const { component, name, icon, to, ...rest } = item
    const Component = component

    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
        //className={"show"}
      >
        {item.items?.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {filteredNavigation && filteredNavigation.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired
}
