import { S3Client, GetObjectCommand, DeleteObjectCommand } from "@aws-sdk/client-s3"
import { getSignedUrl } from "@aws-sdk/s3-request-presigner"

import axios from "axios"

import { useGetPreSignedUrl } from "../../r2upload/api/R2UploaderApi"
import useLoginStore from "src/views/login/store/LoginStore"

const useS3Client = () => {
  const { trigger: requestUploadUrl } = useGetPreSignedUrl()
  const { bucketList } = useLoginStore()

  const S3 = new S3Client({
    region: "auto",
    endpoint: `https://c043e91c4226a6a62c00420249cc9aed.r2.cloudflarestorage.com`,
    credentials: {
      accessKeyId: "aca66faf0c66d2640c6e321853fb67fa",
      secretAccessKey: "af906e14183c22c21b13e8c15392607793bcbe5159123803c78484d12db330a4"
    }
  })

  const getR2PreSignedUrl = async (BucketType, fileName) => {
    const response = await getSignedUrl(
      S3,
      new GetObjectCommand({
        Bucket: BucketType,
        Key: fileName
      }),
      {
        expiresIn: 3600
      }
    )
    return response
  }

  const getUploadUrl = async (BucketType, fileName) => {
    const response = await requestUploadUrl({
      bucket: BucketType,
      file_name: fileName
    })

    return response.data.res_data
  }

  const deleteR2File = async (BucketType, oldFileName) => {
    const response = await S3.send(
      new DeleteObjectCommand({
        Bucket: BucketType,
        Key: oldFileName
      })
    )
    return response
  }

  const uploadR2File = async (preSignedUrl, fileObject) => {
    const response = await axios.put(preSignedUrl, fileObject, {
      headers: {
        "Content-Type": fileObject?.type
      }
    })

    return response.status
  }

  const deleteR2FileList = async (BucketType, listData) => {
    const fileList = []
    listData.forEach((item) => {
      if (item.sent_mp3_m) fileList.push(item.sent_mp3_m)
      if (item.sent_mp3_w) fileList.push(item.sent_mp3_w)
    })

    if (fileList.length === 0) {
      return
    }

    // Promise.all을 사용하여 모든 삭제 작업이 완료될 때까지 기다립니다.
    await Promise.all(
      fileList.map(async (fileName) => {
        try {
          const response = await S3.send(
            new DeleteObjectCommand({
              Bucket: BucketType,
              Key: fileName
            })
          )
          console.log("loop delete file: ", response)
        } catch (error) {
          console.error("Failed to delete file:", error)
          // 삭제 실패에 대한 예외 처리
        }
      })
    )
  }

  const deleteR2VocaFileList = async (listData) => {
    const fileList = []
    listData.forEach((item) => {
      let bycketType = bucketList.find((bucketItem) => bucketItem.value === item.voca_typ).bucket
      console.log(bycketType)
      if (item.voca_mp3_m)
        fileList.push({
          bucket: bycketType,
          fileName: item.voca_mp3_m
        })
      if (item.voca_mp3_w)
        fileList.push({
          bucket: bycketType,
          fileName: item.voca_mp3_w
        })
      if (item.voca_mp3_k)
        fileList.push({
          bucket: bycketType,
          fileName: item.voca_mp3_k
        })
    })

    console.log("file check: ", fileList)

    if (fileList.length === 0) {
      return
    }

    // Promise.all을 사용하여 모든 삭제 작업이 완료될 때까지 기다립니다.
    await Promise.all(
      fileList.map(async (item) => {
        try {
          const response = await S3.send(
            new DeleteObjectCommand({
              Bucket: item.bucket,
              Key: item.fileName
            })
          )
          console.log("loop delete file: ", response)
        } catch (error) {
          console.error("Failed to delete file:", error)
          // 삭제 실패에 대한 예외
        }
      })
    )
  }

  return {
    getUploadUrl,
    getR2PreSignedUrl,
    uploadR2File,
    deleteR2File,
    deleteR2FileList,
    deleteR2VocaFileList
  }
}

export default useS3Client
