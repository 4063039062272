import moment from "moment"
import "moment/locale/ko"
import { isEmpty } from "lodash"

export const actionType = {
  LIST: "list",
  SELECT: "select",
  INSERT: "insert",
  UPDATE: "update",
  NONE: "none"
}

Object.freeze(actionType)

// 날짜 시간 비교하는 agDateFilter 함수
export const filterParams = {
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    if (!cellValue) {
      return -1
    }
    const dateAsString = moment.utc(cellValue).locale("ko").format("YYYY-MM-DD")

    if (dateAsString === null) {
      return -1
    }

    const dateParts = dateAsString.split("-")
    const cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  }
}

export const filterParamsFormat = {
  comparator: (filterLocalDateAtMidnight, cellValue, format) => {
    const dateAsString = moment.utc(cellValue, "YYYYMMDDHHmmss").locale("ko").format("YYYY-MM-DD")

    if (dateAsString === null) {
      return -1
    }

    const dateParts = dateAsString.split("-")
    const cellDate = new Date(Number(dateParts[0]), Number(dateParts[1]) - 1, Number(dateParts[2]))
    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1
    }
    return 0
  }
}

export const isObjEmpty = (obj) => {
  if (obj.constructor === Object && isEmpty(obj)) {
    return true
  }
  return false
}

export const validationOnFocus = (gridRef, index, colName) => {
  gridRef.current.api.setFocusedCell(index, colName)
  gridRef.current.api.startEditingCell({ rowIndex: index, colKey: colName })
  gridRef.current.api.undoCellEditing()
}

export const validation = (params, validationFocus) => {
  if (params.data.cod_mgr_no.length !== 7) {
    alert("코드관리번호는 7자리이어야만 합니다.")
    validationFocus(params.rowIndex, "cod_mgr_no")
    return
  }

  if (!params.data.cod_cd) {
    alert("코드 CD는 필수입니다.")
    validationFocus(params.rowIndex, "cod_cd")
    return
  }

  if (params?.data?.cod_cd_pre?.length >= 4) {
    alert("상위 코드는 3글자까지만 허용합니다.")
    validationFocus(params.rowIndex, "cod_cd_pre")
    return
  }
}

export const insertTransactionAsync = (gridRef) => {
  gridRef.current.api.applyTransactionAsync({ add: [{ insert: true }], addIndex: 0 }, (params) => {
    const st = setTimeout(() => {
      gridRef.current.api.startEditingCell({ rowIndex: 0, colKey: "cod_mgr_nm" })
      gridRef.current.api.refreshCells()
      clearTimeout(st)
    }, 100)
  })
}
